.-cursor-pointer .rt-resizable-header-content:after {
    content: "\f107";
    font-family: Fontawesome;
    margin-left: 10px;
    transition: .5s ease all;
    -webkit-transition: .5s ease all;
    -moz-transition: .5s ease all;
    -ms-transition: .5s ease all;
    -o-transition: .5s ease all;
}

.-cursor-pointer .rt-resizable-header-content {
    position: relative;
} 

.-cursor-pointer.-sort-asc .rt-resizable-header-content:after {
    content: "\f106";
}