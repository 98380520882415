* {
  box-sizing: border-box;
  font-style: normal;
}

.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: 414px;
}

.dashboard-aside-container,
.add-admin-text-container,
.operators-container {
  height: 14%;
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.dashboard-aside {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.008em;
  color: #ffd100;
}

.dashboard-aside:hover {
  color: #ffd100;
}

.add-admin-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.008em;
  color: #72879f;
}

.add-admin-text:hover {
  color: #72879f;
}

.operators-container {
  width: 80%;
}

.operators {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.008em;
  color: #72879f;
}

.admin-settings-container {
  height: 30%;
  width: 80%;
}

.settings-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.008em;
  color: #72879f;
}

.settings-text:hover {
  color: #72879f;
}

/* .happy-operators-img-wrapper,
.admin-center-img-wrapper,
.listed-parking-lots-img-wrapper {
  width: 17%;
} */

.happy-operators-text,
.admin-center-text,
.listed-parking-lots-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.happy-operators-text > strong,
.admin-center-text > strong,
.listed-parking-lots-text > strong {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #252733;
}

.happy-operators-text > span,
.admin-center-text > span,
.listed-parking-lots-text > span {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #9fa2b4;
}

form.operators-input-search {
  border: none;
  padding: 0;
}

input.operators-input-search {
  width: 319px;
  border: 1px solid #ffd100;
  border-radius: 10px;
  display: block;
  padding: 9px 4px 9px 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #b5b7c0;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.operators-attributes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.operators-attributes > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #72879f;
}

.operators-attributes .list-data {
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-invoice .money-value {
  font-weight: 600;
  color: #362e2e;
}
